@import '../../../variables';
.PollTimeSet {
  min-width: 100%;
  min-height: 260px;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 16px 16px 0px 0px;
  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $borderPrimary;
    padding: 16px;
    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $fontColorSecondary;
    }
    img {
      cursor: pointer;
    }
  }
  &__body {
    padding: 30px 16px 30px 16px;
    border-bottom: 1px solid $borderPrimary;

    &__label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $fontColorSecondary;
      margin-bottom: 10px;
      padding-left: 2px;
      display: flex;
    }
    &__infotext {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: $fontColorPrimary;
      margin-top: 10px;
      padding-left: 2px;
    }
  }
  &__footer {
    padding: 20px 16px;
  }
}
