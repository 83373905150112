.Chip {
  padding: 4px 8px;
  color: white;
  background: #009ae0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: normal;
  margin: 5px 5px;
  &__Text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 3px;
    display: flex;
    align-items: center;
    max-width: 100%;
    white-space: nowrap;
    color: #ffffff;
  }
  &__Delete {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
