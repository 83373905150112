@import './src/_variables';
.Inputfieldlengthcounter {
  width: 10%;
  margin: auto;
  text-align: right;
  padding-right: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: right;
  color: #666666;
  opacity: 0.6;
}
.Inputfieldlengthcounter.error {
  color: $errorColor;
}
