@import '../../../_variables';

.radio {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: $blue;
  cursor: pointer;
}

.radio__label {
  line-height: 1;
  transition: 180ms all ease-in-out;
  opacity: 0.8;
  color: $fontColorSecondary;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.radio__input {
  display: flex;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

input:checked + .radio__control {
  border: 0.1em solid currentColor;
}

.radio-before {
  .radio__control {
    display: grid;
    place-items: center;
  }

  input + .radio__control::before {
    content: '';
    width: 0.5em;
    height: 0.5em;
    box-shadow: inset 0.5em 0.5em currentColor;
    border-radius: 50%;
    transition: 180ms transform ease-in-out;
    transform: scale(0);
    border: 0.1em solid currentColor;
  }

  input:checked + .radio__control::before {
    transform: scale(1);
  }
}

.radio__control {
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 0.1em solid $borderSecondary;
  transform: translateY(-0.05em);
}
