@import './src/_variables';
.input-field {
  background-color: $white;
  color: #000000;
  font-size: 14px;
  border-radius: 8px;
  height: 47px;
  border: none;
  width: 90%;
  padding: 0 5px 0 13px;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}
.input-field.error {
  color: $errorColor;
}
.input-field:placeholder {
  color: $fontColorPrimary;
}
