@import '../../../variables';
.YourPoll {
  background-color: #f2f2f2;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;

  &__Heading {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 8px;
  }
  &__ButtonWrapper {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 12px;
    right: 8px;
    &__addButton {
      cursor: pointer;
    }
  }
}
.YourPoll.present {
  min-height: 100vh;
  margin-top: 56px;
}
.YourPoll.empty {
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;
  background-color: $white;
  &__imgDiv {
    margin: auto;
    margin-bottom: 0;
    background-color: white;
    padding: 0;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 55%;
    left: 50%;
    width: 100%;
    img {
      width: 120px;
      margin: 0 auto;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $white;
    padding: 0;
    row-gap: 0;
    width: 100%;
    margin-top: 16px;
    p {
      margin: 0;
      font-weight: 600;
    }
    &__p2 {
      font-style: normal;
      font-weight: normal !important;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      margin: 0;
      padding: 0;
      background: white;
      color: #666666;
      margin-top: 4px !important;
    }
  }
}

.MaxLimit {
  background: #ffffff;
  border-radius: 4px;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  &__Logo {
    display: flex;
    justify-content: center;
  }
  &--Bold {
    padding-top: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    text-align: center;

    color: rgba(0, 0, 0, 0.87);
  }
  &__Description {
    padding-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    text-align: center;

    color: rgba(0, 0, 0, 0.6);
  }
  &__okay {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 20px;
    color: #009ae0;
    padding-bottom: 20px;
  }
}

.bottom {
  position: absolute;
  bottom: 5px;
}
@media screen and(min-width:601px) {
  .YourPoll__ButtonWrapper {
    transform: translate(-120%, -19%);
  }
}
@media screen and(min-width:992px) {
  .YourPoll__ButtonWrapper {
    transform: translate(-270%, -19%);
  }
}
@media screen and(min-width:1100px) {
  .YourPoll__ButtonWrapper {
    transform: translate(-370%, -19%);
  }
}
