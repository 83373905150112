.slick-dots li button:before {
  color: rgba(0, 154, 224, 0.4) !important;
  font-size: 14px !important;
}

.slick-dots li.slick-active button:before {
  color: #009ae0 !important;
  font-size: 14px;
}

.Carousel__Wrapper {
  &__empty {
    height: 0;
  }

  &__empty.count2 {
    min-height: 240px;
  }

  &__empty.count4 {
    min-height: 360px;
  }

  &__empty.count3 {
    min-height: 300px;
  }
}

.Carousel {

  &__Wrapper.count2 {
    min-height: 370px;
  }

  &__Wrapper.count3 {
    min-height: 400px;
  }

  &__Wrapper.count4 {
    min-height: 460px;
  }

  &__Wrapper {
    background-repeat: no-repeat;
    background-size: 100% 90%;
    background-position: center;

    min-width: 320px;
    box-sizing: border-box;
    left: 0px;
    top: 219px;
    border-radius: 0px;
    margin: auto;
    padding: 16px;
    padding-bottom: 90px;
    background-image: url('../../../assests/images/CarouselBG.png');
    min-height: 300px;

    &__Container {
      background: transparent;
      background-size: cover;
      box-sizing: border-box;
      border-radius: 8px;
      max-height: 489px;
      background-size: contain;
      background-position: 6% 130%;

    }
  }
}

.Studentpoll__Card.unselected {
  row-gap: 0px;
}

.Studentpoll__Card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px;
  border-radius: 8px;
  row-gap: 15px;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  background: white;

  &__Heading {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.87);
  }

  &__Time {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #666666;
    margin-top: 16px;
  }
}

.nopolls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-slider.count4 {
  min-height: 360px;
}

.slick-slider.count3 {
  min-height: 300px;
}

.slick-slider.count2 {
  min-height: 240px;
}

.slick-slider.count1 {
  min-height: 230px;
}

.slick-dots li {
  margin: 0 -1px;
}

.livePollsHeading {
  margin-left: 2px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #3C4852;
}