@import '../../../variables';

.selectbc {
  margin-top: 44px;
  padding-bottom: 79px;
}
.BC_Selection_Wrapper {
  background-color: $white;
  padding: 16px;
  border-top: 1px solid $borderPrimary;
}
.BC_Selection_Wrapper.bottom {
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  width: 70%;
}
.SelectAll {
  border-bottom: 1px solid $borderPrimary;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  &__Text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
  &__CheckBoxContainer {
    .radio__label {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #666666;
    }
  }
}
