.SinglPollStaticstics {
  background-color: white;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 56px;
  .CompletedCard {
    border: 1px solid #e5e5e5;
  }
}
.StatisticsCard__element__text__graph.highest {
  background-color: #75caf9;
}
.StatisticsCard {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  &__element {
    display: flex;
    width: 100%;
    margin: 7px 0;
    overflow: hidden;
    justify-content: space-between;
    &__text {
      max-width: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      &--text {
        display: flex;
        position: absolute;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        min-width: 165px;
        display: flex;
        padding-left: 10px;
        align-items: center;
      }
      &__graph {
        min-height: 32px;
        display: flex;

        justify-content: flex-start;
        align-items: center;
        padding: 5px 0px 5px 5px;
        border-radius: 4px;
        width: 0%;
        background: #c4cfd6;
        opacity: 1;
        -webkit-animation: 1.2s 0.4s forwards;
        -moz-animation: growIn 1.2s 0.4s forwards;
        animation: 1.2s 0.4s forwards;
      }
      &__graph.show {
        opacity: 1;
        animation: growIn 1.2s 0s forwards;
      }
      &__graph.student {
        background: #eaeaea;
        // animation: 1s anim-lightspeed-in ease forwards
        // transition: width 1s ease-in-out;
        transition-delay: 2s;
      }
    }
    &__completed {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 40px;
      margin-left: 10px;
      margin-right: 4px;
    }
  }
}

@keyframes growIn {
  0% {
    width: 0;
  }
  100% {
    // width: 0%;
  }
}
