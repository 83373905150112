@import '../../../variables';
.CourseCard {
  &__Heading {
    background-color: $backgroundSecondary;
    padding: 20px 20px;
    display: flex;
    justify-content: space-between;
    &--left {
      color: $fontColorPrimary;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
    &--right {
      color: $blue;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      cursor: pointer;
    }
  }
  &__Body {
    background-color: $white;
    display: grid;
    row-gap: 20px;
    padding: 20px 20px;
    max-height: 65vh;
    overflow: auto;
  }
}
