@import '../../variables';
.question-form {
  display: grid;
  grid-row-gap: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  margin-top: 56px;
  h3 {
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 500;
  }
}
.poll-length {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 20px;
  padding-top: 0px;
  h3 {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
  }
  &__buttonWrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    justify-content: space-between;
    width: 100%;
  }
}
.assign {
  padding: 10px 20px;
  h3 {
    font-size: 16px;
    font-weight: 500;
  }
}
