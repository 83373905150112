.CompletedCard {
  min-width: 220px;
  min-height: 108px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  &__part--above {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    color: rgba(0, 0, 0, 0.87);
    overflow: hidden;
    margin-bottom: auto;
  }
  &__hr {
    border: 1px solid #e5e5e5;
    width: 100%;
    background-color: #e5e5e5;
    background: #e5e5e5;
  }
  &__part--below {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__responded {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #666666;
    }
    &__Time {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      margin-left: 8px;
    }
    &__Time.completed {
      li {
        list-style: none;
        color: #009ae0;
      }
      li:before {
        content: '•    '; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: #c4c4c4 !important;
        margin-right: 5px;
      }
    }
    &__Time.remaining {
      li {
        list-style: none;
        color: #56be81;
      }

      li:before {
        content: '•    '; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: #c4c4c4 !important;
        margin-right: 5px;
      }
    }
  }
}

.CompletedCard.noshadow {
  box-shadow: none;
}
.CompletedCard.margin {
  margin: 8px 0;
}
