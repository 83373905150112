@import './src/_variables';
.InputFieldWrapper {
  width: 100%;
  height: 48px;
  overflow: hidden;
  display: flex;
  align-items: center;
  background: $white;
  border: 1px solid $borderPrimary;
  box-sizing: border-box;
  border-radius: 8px;
}
.focus {
  border: 1px solid $fillBlue;
}
