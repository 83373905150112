@import url('https://fonts.googleapis.com/css?family=Lato');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto;
  font-family: 'Lato', sans-serif;
  background: #E5E5E5;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root{
  min-height: 100vh;
  background: white;
}
.errorDiv{
  transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    margin: auto;
    text-align: center;
    font-size: 16px;
}
input:-internal-autofill-selected {
  background-color: transparent !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

/* @media screen and (max-height:640px){
  .infinite-scroll-component.scroll-all-fields-present{

  }
  .infinite-scroll-component.scroll-one-field-empty{
   
  }
  .scrollable{
    height: 55vh;
    overflow: auto;
  }
  .scrollable.more{
    height: 65vh;
  }
  
}
.scrollable{
  overflow: auto;
} */
.scroll{
  overflow: auto;
  height: calc(97vh - 230px);
}
.scroll.more{
  overflow: auto;
  height: calc(100vh - 150px);
}
@media  screen and (min-height:641px) and (max-height:840px) {
  /* .infinite-scroll-component {
    height: 72vh;
  } */
}
.createpoll__wrapper{
  overflow: scroll;
    max-height: 82vh;
    padding-bottom: 40px;
}