@import './src/_variables';
.Button {
  cursor: pointer;
  justify-content: center;
  margin: auto;
  display: flex;
  align-items: center;
  outline: none;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  border-radius: 4px;
  background-color: $blue;
  font-size: $fontSizePrimary;
  color: $white;
  min-height: 48px;
  border: 1px solid $blue;
  &:disabled {
    background-color: $disabledButton;
    color: $white;
    cursor: not-allowed;
    border: none;
  }
}
.Button.w-half {
  max-width: 181px;
  min-width: 181px;
  position: absolute;
  bottom: 0px;
  margin-bottom: 40px;
}
.Button.w-full {
  width: 100%;
}
.Button.add {
  background-color: $white;
  color: $blue;
  min-height: 40px;
  border-radius: 8px;
}
.Button.polllength {
  border: 1px solid $borderPrimary;
  color: $fontColorPrimary;
  background-color: $white;
  border-radius: 8px;
  padding: 0 10px;
  margin: 0px;
  min-width: 70px;
}
.Button.polllength.blue {
  background-color: $blue;
  color: $white;
}
.Button.addCourse {
  justify-content: space-between;
  height: 48px;
}
.Button.rounded {
  width: 56px;
  height: 56px;

  /* States_White overlay/Hover */

  background: rgba(255, 255, 255, 0.04);
  border-radius: 28px;
}
.Button.Student {
  background: #ffffff;
  border: 1px solid #009ae0;
  box-sizing: border-box;
  border-radius: 40px;
  font-size: 14px;
  /* Inside Auto Layout */
  width: 100%;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 6px 0px;
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 36px;
  color: #009ae0;
}
