@import '../../../variables';
.Chip__Container {
  display: flex;
  width: 100%;
  &__Wrapper {
    display: flex;
    width: 90%;
    padding: 10px 0px;
    overflow-x: scroll;
  }
  &__Add {
    display: flex;
    width: 10%;
    padding: 10px 0px;
    justify-content: flex-end;
    align-items: center;
  }
}
