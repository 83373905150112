@import './src/_variables';
.Navbar {
  background-color: $blue;
  // min-height: 56px;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  padding-right: 20px;
  padding-left: 20px;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  margin: auto;
  z-index: 1;
  // padding-top: env(safe-area-inset-top);
  padding-bottom: 15px;
  &--back {
    cursor: pointer;
  }
  &__Heading {
    color: $white;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
  &--Delete {
    text-align: right;
    cursor: pointer;
  }
}
