@import '../../../variables';

.checkbox {
  display: flex;
  justify-content: space-between;
  grid-gap: 0.5em;
  font-size: 16px;
  color: $fontColorSecondary;
  cursor: pointer;
  &--disabled {
    color: var(--disabled);
  }
}
input:checked + .checkbox__control {
  border: 1px solid $blue;
}

.checkbox__control {
  display: inline-grid;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid $borderSecondary;

  svg {
    transition: transform 0.1s ease-in 25ms;
    transform: scale(0);
    transform-origin: bottom left;
    color: $blue;
  }
}

.checkbox__input {
  display: grid;
  grid-template-areas: 'checkbox';

  > * {
    grid-area: checkbox;
  }

  input {
    opacity: 0;
    width: 1em;
    height: 1em;

    &:checked + .checkbox__control svg {
      transform: scale(1);
      color: $blue;
    }
  }
}
