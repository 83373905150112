$blue: #009ae0;
$white: #ffffff;
$backgroundSecondary: #f1f1f1;
$borderPrimary: #e5e5e5;
$borderSecondary: #cccccc;
$fontColorPrimary: #666666;
$fontColorSecondary: #000000;
$errorColor: #ff4058;
$disabledButton: #c4c4c4;
$fillBlue: #75caf9;
$fillGrey: #c4cfd6;
$green: #56be81;

$fontSizePrimary: 16px;
$fontSizeSecondary: 14px;
