@import '../../../variables';
.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  min-width: 100%;
  min-height: 200px;
  max-width: 100%;
  transform: translate(-50%, -50%);
  justify-content: center;
}
.Modal.bottom {
  transform: none;

  bottom: 0;
  top: unset;
  left: unset;
}
.Overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
}
