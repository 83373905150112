.close {
  text-align: right;
  padding-right: 12px;
  padding-top: 10px;
  margin-bottom: -10px;
}
.ModalWrapper {
  background: white;
  margin: 0 16px;
  border-radius: 4px;
}
